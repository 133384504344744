.productscreen {
    max-width: 1300px;
    margin: 1rem auto;
    display: flex;
    .productscreen-left {
        display: flex;
        flex: 0.8;
        .left-image {
            margin: 1rem;
            flex: 0.6;
        }
        .left-info {
            margin: 1rem;
            flex: 0.4;
            background: #FFF;
            height: fit-content;
            font-size: 0.9rem;
            .left-name {
                font-weight: bold;
                font-size: 1.3rem;
            }
            p {
                padding: 1rem;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            }
            p:last-child {
                border:none
            }
        }
    }
    .productscreen-right {
        flex: 0.2;
        .right-info {
            width: 250px;
            margin: 1rem;
            background: #FFF;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
            p {
                padding: 1rem;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                font-size: 0.8rem;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                button {
                    grid-column: 1/-1;
                    padding: 10px 16px;
                    background: #171717;
                    color: #F4F4F4;
                    border: 1px solid #171717;
                    cursor: pointer;
                }
            }
            p:last-child {
                border: none;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .productscreen {
        flex-direction: column;
        .productscreen-left {
            flex-direction: column;
            flex: 1;
            .left-image {
                flex: 1;
            }
            .left-info {
                flex: 1;
            }
        }
        .productscreen-right {
            flex: 1;
            padding: 1rem;
            .right-info {
                width: 100%;
                margin: 0;
            }
        }
    }
}