* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  background: #F4F4F4;
}

img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.navbar {
  width: 100%;
  height: 98px;
  background-color: #171717;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem;
  z-index: 50;
}
.navbar .navbar-logo a {
  text-decoration: none;
}
.navbar .navbar-logo a h2 {
  color: #F4F4F4;
  font-size: 1.5rem;
  cursor: pointer;
}
.navbar .navbar-links {
  display: flex;
  list-style-type: none;
  align-items: center;
}
.navbar .navbar-links > li {
  padding-left: 1.5rem;
}
.navbar .navbar-links > li .cart-link {
  background: #333;
  padding: 10px;
  border-radius: 8px;
}
.navbar .navbar-links > li .cart-link:hover {
  background-color: #dd219e;
  color: #F4F4F4;
}
.navbar .navbar-links > li > a {
  text-decoration: none;
  color: #F4F4F4;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
}
.navbar .navbar-links > li > a > span {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.navbar .navbar-links > li > a > span .cartlogo-badge {
  width: 30px;
  height: 30px;
  background: #F4F4F4;
  border-radius: 50%;
  margin-left: 8px;
  color: #171717;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1rem;
}
.navbar .hamburger-menu {
  width: 30px;
  height: 30px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.navbar .hamburger-menu:hover > div {
  background-color: #dd219e;
}
.navbar .hamburger-menu > div {
  width: 100%;
  height: 3px;
  background-color: #F4F4F4;
}

@media screen and (max-width: 960px) {
  .navbar .navbar-links {
    display: none;
  }
  .navbar .hamburger-menu {
    display: flex;
  }
}
@media screen and (max-width: 500px) {
  .navbar .navbar-logo h2 {
    font-size: 1rem;
  }
}
.sidedrawer {
  width: 70%;
  height: 100vh;
  background: #FFF;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateX(-100%);
  transition: all 0.3s ease-out;
}
.sidedrawer .sidedrawer-links {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}
.sidedrawer .sidedrawer-links li {
  display: flex;
  align-items: center;
}
.sidedrawer .sidedrawer-links li a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  padding: 1rem;
  text-decoration: none;
  color: #171717;
  font-size: 1.5rem;
}
.sidedrawer .sidedrawer-links li a:hover {
  background: #171717;
  color: #F4F4F4;
}
.sidedrawer .sidedrawer-links li a:hover .sidedrawer-cartbadge {
  background: #F4F4F4;
  color: #171717;
}
.sidedrawer .sidedrawer-links li a span {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.sidedrawer .sidedrawer-links li a span .sidedrawer-cartbadge {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #171717;
  color: #F4F4F4;
  font-size: 1rem;
  margin-left: 8px;
}

.sidedrawer.show {
  transform: translateX(0);
}

@media screen and (min-width: 960px) {
  .sidedrawer {
    display: none;
  }
}
.backdrop {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
}

.homescreen {
  max-width: 1300px;
  margin: 1rem auto;
}
.homescreen .homescreen-title {
  font-size: 1.5rem;
  color: #171717;
  margin-bottom: 1rem;
  margin-left: 8px;
}
.homescreen .homescreen-products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 1232px) {
  .homescreen .homescreen-products {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 960px) {
  .homescreen .homescreen-products {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 630px) {
  .homescreen .homescreen-products {
    grid-template-columns: repeat(1, 1fr);
  }
}
.productscreen {
  max-width: 1300px;
  margin: 1rem auto;
  display: flex;
}
.productscreen .productscreen-left {
  display: flex;
  flex: 0.8;
}
.productscreen .productscreen-left .left-image {
  margin: 1rem;
  flex: 0.6;
}
.productscreen .productscreen-left .left-info {
  margin: 1rem;
  flex: 0.4;
  background: #FFF;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 0.9rem;
}
.productscreen .productscreen-left .left-info .left-name {
  font-weight: bold;
  font-size: 1.3rem;
}
.productscreen .productscreen-left .left-info p {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.productscreen .productscreen-left .left-info p:last-child {
  border: none;
}
.productscreen .productscreen-right {
  flex: 0.2;
}
.productscreen .productscreen-right .right-info {
  width: 250px;
  margin: 1rem;
  background: #FFF;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
}
.productscreen .productscreen-right .right-info p {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 0.8rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.productscreen .productscreen-right .right-info p button {
  grid-column: 1/-1;
  padding: 10px 16px;
  background: #171717;
  color: #F4F4F4;
  border: 1px solid #171717;
  cursor: pointer;
}
.productscreen .productscreen-right .right-info p:last-child {
  border: none;
}

@media screen and (max-width: 960px) {
  .productscreen {
    flex-direction: column;
  }
  .productscreen .productscreen-left {
    flex-direction: column;
    flex: 1;
  }
  .productscreen .productscreen-left .left-image {
    flex: 1;
  }
  .productscreen .productscreen-left .left-info {
    flex: 1;
  }
  .productscreen .productscreen-right {
    flex: 1;
    padding: 1rem;
  }
  .productscreen .productscreen-right .right-info {
    width: 100%;
    margin: 0;
  }
}
.cartscreen {
  display: flex;
  max-width: 1300px;
  margin: 2rem auto;
}
.cartscreen .cartscreen-left {
  flex: 0.7;
  margin-right: 1rem;
  background-color: transparent;
  padding: 1rem;
}
.cartscreen .cartscreen-left h2 {
  margin-bottom: 1rem;
}
.cartscreen .cartscreen-right {
  flex: 0.3;
  background: #FFF;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.cartscreen .cartscreen-right div {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem;
}
.cartscreen .cartscreen-right div button {
  padding: 10px 17px;
  width: 100%;
  background: #171717;
  color: #F4F4F4;
  border: 1px solid #171717;
  cursor: pointer;
}
.cartscreen .cartscreen-right div button:hover {
  opacity: 0.9;
}
.cartscreen .cartscreen-right div:last-child {
  border: none;
}
.cartscreen .cartscreen-right .cartscreen-info p {
  padding: 8px;
}

@media screen and (max-width: 1320px) {
  .cartscreen {
    max-width: 900px;
  }
}
@media screen and (max-width: 960px) {
  .cartscreen {
    max-width: 800px;
    flex-direction: column;
  }
  .cartscreen .cartscreen-left {
    margin: 0;
  }
  .cartscreen .cartscreen-right {
    margin: 1rem;
  }
}
.product {
  width: 300px;
  padding: 1rem;
  background: #FFF;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  margin: 8px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product img {
  height: 170px;
  border-radius: 10px;
}
.product .product-info > p {
  margin-bottom: 8px;
}
.product .info-price {
  font-weight: bolder;
}
.product .info-name {
  font-size: 1rem;
  overflow: hidden;
}
.product .info-description {
  font-size: 0.8rem;
}
.product .info-button {
  display: block;
  width: 100%;
  text-decoration: none;
  text-align: center;
  color: #171717;
  background: #F4F4F4;
  padding: 8px 16px;
  border: 1px solid #171717;
  font-size: 1rem;
}
.product .info-button:hover {
  background: #171717;
  color: #F4F4F4;
}

@media screen and (max-width: 1232px) {
  .product {
    width: 360px;
  }
}
@media screen and (max-width: 1115px) {
  .product {
    width: 330px;
  }
}
@media screen and (max-width: 1028px) {
  .product {
    width: 300px;
  }
}
@media screen and (max-width: 960px) {
  .product {
    width: 400px;
  }
}
@media screen and (max-width: 830px) {
  .product {
    width: 350px;
  }
}
@media screen and (max-width: 830px) {
  .product {
    width: 350px;
  }
}
@media screen and (max-width: 700px) {
  .product {
    width: 290px;
  }
}
@media screen and (max-width: 630px) {
  .product {
    width: 450px;
  }
}
@media screen and (max-width: 500px) {
  .product {
    width: 350px;
  }
}
@media screen and (max-width: 500px) {
  .product {
    width: 300px;
  }
}
.cartitem {
  width: 100%;
  padding: 1rem;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr 1fr 1fr;
  gap: 8px;
  background: #FFF;
  border-radius: 2px;
  place-items: center;
}
.cartitem .cartitem_name {
  text-decoration: none;
  color: #171717;
}
.cartitem .cartitem_name:hover {
  color: #dd219e;
}
.cartitem .cartitem-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.5px solid rgba(177, 177, 177, 0.9);
  border-radius: 5px;
  width: 100px;
}
.cartitem .cartitem-quantity button {
  border: none;
  background: none;
  outline: none;
  padding: 0.5rem 0.75rem;
  color: #171717;
  cursor: pointer;
}
.cartitem .cartitem-delete {
  padding: 10px 17px;
  color: red;
  background: #F4F4F4;
  border: 1px solid #171717;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.cartitem .cartitem-delete:hover, .cartitem .cartitem-delete:focus, .cartitem .cartitem-delete:active {
  background: #171717;
  transform: scale(1.2);
}

@media screen and (max-width: 700px) {
  .cartitem .cartitem_name {
    font-size: 0.8rem;
  }
  .cartitem .cartitem-delete {
    padding: 8px 13px;
  }
  .cartitem .cartitem-price {
    font-size: 0.8rem;
  }
  .cartitem .cartitem-quantity {
    width: 80px;
  }
}
@media screen and (max-width: 500px) {
  .cartitem .cartitem_name {
    font-size: 0.6rem;
  }
  .cartitem .cartitem-delete {
    padding: 5px 8px;
  }
  .cartitem .cartitem-price {
    font-size: 0.6rem;
  }
  .cartitem .cartitem-quantity {
    width: 70px;
  }
}/*# sourceMappingURL=style.css.map */