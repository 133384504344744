.cartscreen {
    display: flex;
    max-width: 1300px;
    margin: 2rem auto;
    .cartscreen-left {
        flex: 0.7;
        margin-right: 1rem;
        background-color: transparent;
        padding: 1rem;
        h2{
            margin-bottom: 1rem;
        }
    }
    .cartscreen-right {
        flex: 0.3;
        background: #FFF;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
        height: fit-content;
        div {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            padding: 1rem;
            button {
                padding: 10px 17px;
                width: 100%;
                background: #171717;
                color: #F4F4F4;
                border: 1px solid #171717;
                cursor: pointer;
                &:hover {
                    opacity: 0.9;
                }
            }
        }
        div:last-child {
            border: none;
        }
        .cartscreen-info p {
            padding: 8px;
        }
    }
}

@media screen and (max-width: 1320px){
    .cartscreen {
        max-width: 900px;
    }
}

@media screen and (max-width: 960px){
    .cartscreen {
        max-width: 800px;
        flex-direction: column;
        .cartscreen-left {
            margin: 0;
        }
        .cartscreen-right {
            margin: 1rem;
        }
    }
}