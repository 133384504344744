.homescreen {
    max-width: 1300px;
    margin: 1rem auto;
    .homescreen-title {
        font-size: 1.5rem;
        color: #171717;
        margin-bottom: 1rem;
        margin-left: 8px;
    }
    .homescreen-products {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 1232px) {
    .homescreen .homescreen-products {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 960px) {
    .homescreen .homescreen-products {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 630px) {
    .homescreen .homescreen-products {
        grid-template-columns: repeat(1, 1fr);
    }
}