.product {
    width: 300px;
    padding: 1rem;
    background: #FFF;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    margin: 8px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
        height: 170px;
        border-radius: 10px;
    }

    .product-info > p {
        margin-bottom: 8px;
    }

    .info-price {
        font-weight: bolder;
    }

    .info-name {
        font-size: 1rem;
        overflow: hidden;
    }

    .info-description {
        font-size: 0.8rem;
    }
    .info-button {
        display: block;
        width: 100%;
        text-decoration: none;
        text-align: center;
        color: #171717;
        background: #F4F4F4;
        padding: 8px 16px;
        border: 1px solid #171717;
        font-size: 1rem;
        &:hover {
            background: #171717;
            color: #F4F4F4;
        }
    }
}

@media screen and (max-width: 1232px) {
    .product {
        width: 360px;
    }
}

@media screen and (max-width: 1115px) {
    .product {
        width: 330px;
    }
}

@media screen and (max-width: 1028px) {
    .product {
        width: 300px;
    }
}

@media screen and (max-width: 960px) {
    .product {
        width: 400px;
    }
}

@media screen and (max-width: 830px) {
    .product {
        width: 350px;
    }
}

@media screen and (max-width: 830px) {
    .product {
        width: 350px;
    }
}

@media screen and (max-width: 700px) {
    .product {
        width: 290px;
    }
}

@media screen and (max-width: 630px) {
    .product {
        width: 450px;
    }
}

@media screen and (max-width: 500px) {
    .product {
        width: 350px;
    }
}

@media screen and (max-width: 500px) {
    .product {
        width: 300px;
    }
}
